import { ExibirCliente } from "../../components/CRUD Cliente/ExibirCliente";
// import { CriarCliente } from "../../components/CRUD Cliente/CriarCliente";

function Cliente() {
    return (
        <>
            {/* <CriarCliente /> */}
            <ExibirCliente />
        </>
    )
}

export { Cliente };