// import { CriarArtigo } from "../../components/CRUD Artigo/CriarArtigo";
import { ExibirArtigo } from "../../components/CRUD Artigo/ExibirArtigo";

function Artigo() {
        return (
            <>
                {/* <CriarArtigo /> */}
                <ExibirArtigo />
            </>
        )
    }

export { Artigo };